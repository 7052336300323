import {Component, OnInit} from '@angular/core';
import {MemberModel} from "../../models/member.model";
import {BackendService} from "../../service/backend.service";
import {initFlowbite} from "flowbite";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {response} from "express";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.css'
})
export class MainComponent implements OnInit{

  requestDTO: MemberModel = new MemberModel();
  memberForm: FormGroup;
  alertSuccess: boolean | undefined;

  constructor(private service: BackendService, private formBuilder: FormBuilder) {
    this.memberForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern('^[\\w\\.-]+@[\\w\\.-]+\\.\\w+$')]],
      lgpdConsent: [false, [Validators.requiredTrue]]
    });
  }

  ngOnInit() {
    initFlowbite()
  }

  onSubmit(){
    if (this.memberForm.valid) {
      this.requestDTO.name = this.memberForm.value.name;
      this.requestDTO.phone = this.transform(this.memberForm.value.phone);
      this.requestDTO.email = this.memberForm.value.email;
      this.requestDTO.agree_with_terms = this.memberForm.value.lgpdConsent
      this.service.createMember(this.requestDTO).subscribe({next: (response)=>{
          this.alertSuccess = true
        }});
    } else {
      this.alertSuccess = false
    }
  }

  transform(value: string | number): string {
    let phone = value.toString();

    if (phone.length === 11) {
      const ddd = phone.slice(0, 2);
      const firstPart = phone.slice(2, 7);
      const secondPart = phone.slice(7, 11);

      return `(${ddd})${firstPart}-${secondPart}`;
    }

    return value.toString();
  }
close(){
    this.alertSuccess = undefined;
}
  get controlForm() {
    return this.memberForm.controls;
  }

}
