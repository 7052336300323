export const environment = {
  production: false,
  label: 'prod',
  apiPath: 'https://',
  endpoints: {
    backend: {
      postMember: "asd"
    }
  },
  firebase: {
    apiKey: "AIzaSyCbC3PddRxlqvzuLxJipURGoCot6v0NLUE",
    authDomain: "veredas-tech-landing-page.firebaseapp.com",
    projectId: "veredas-tech-landing-page",
    storageBucket: "veredas-tech-landing-page.appspot.com",
    messagingSenderId: "421852787992",
    appId: "1:421852787992:web:9a5deb26d5b34985cf1a6d",
    measurementId: "G-19EELDFEQF"
  }
}
