<section class="p-3 sm:p-5">
  <div class="mx-auto max-w-screen-xl px-4 lg:px-12">
    <div class="w-full flex justify-center">
      <img class="w-[40%] md:w-[30%] lg:w-[20%] mb-3" src="assets/veredas.png">
    </div>
    <div class="w-full flex justify-end">
      <button type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" (click)="exportToCsv()">Exportar</button>
    </div>
    <!-- Start coding here -->
    <div class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
      <div class="overflow-x-auto">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-4 py-3">Nome Completo</th>
            <th scope="col" class="px-4 py-3">Email</th>
            <th scope="col" class="px-4 py-3">Telefone</th>
            <th scope="col" class="px-4 py-3">Aceitou receber comunicados?</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let member of members" class="border-b dark:border-gray-700">
            <th scope="row" class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{{member.name}}</th>
            <td class="px-4 py-3">{{member.email}}</td>
            <td class="px-4 py-3">{{member.phone}}</td>
            <td *ngIf="member.agree_with_terms" class="px-4 py-3">Sim</td>
            <td *ngIf="!member.agree_with_terms" class="px-4 py-3">Não</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
