import { Injectable, inject } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router"
import {MemberModel} from "../models/member.model";
import {catchError, Observable, throwError} from "rxjs";
import {MembersModel} from "../models/members.model";

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  api= "https://landing.api.veredasdoreino.com.br/userdata"
  httpHeaders = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': '*/*'})
  }
  constructor(private http: HttpClient) {
  }
  createMember(member: MemberModel): Observable<MemberModel>{
    return this.http.post<MemberModel>(this.api, member, this.httpHeaders).pipe(catchError(this.handleError));
  }

  getMembers(): Observable<any>{
    return this.http.get<any>(this.api).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse){
    let msg = '';
    if (error.error instanceof ErrorEvent){
      msg = error.error.message;
    } else {
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg)
  }
}
