import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {MainComponent} from "./pages/main/main.component";
import {LoginComponent} from "./pages/login/login.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {AuthGuardService} from "./service/auth-guard.service";

const routes: Routes = [
  {path: '', component: MainComponent},
  {path: 'restrito', redirectTo: 'restrito/login', pathMatch: 'full'},
  {path: 'restrito/login', component: LoginComponent},
  {path: 'restrito/resumo', component: DashboardComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
