<div class="flex flex-col lg:flex-row min-h-screen">
  <!-- Left Section -->
  <div class="lg:w-1/2 flex justify-start p-8 bg-cover bg-center" style="background-image: url('assets/maos.jpg');">
    <div class="w-full text-start justify-start">
      <img src="assets/logo.png" alt="Veredas do Reino" class="mx-auto lg:ml-[20%] w-[45%] md:w-[30%] lg:w-[40%] mb-8">
      <h1 class="text-5xl lg:text-8xl ml-[20%] lg:mt-[20%] text-white font-inter">VOCÊ <br><span class="font-bold">NÃO ESTÁ</span> <br>SOZINHO</h1>
    </div>
  </div>
  <!-- Right Section -->
  <div class="lg:w-1/2 flex items-center justify-center p-8 bg-white">
    <div class="w-[70%]">
      <div *ngIf="alertSuccess" id="toast-success" class="flex items-center w-full p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
          </svg>
          <span class="sr-only">Check icon</span>
        </div>
        <div class="ms-3 text-sm font-normal">Confirmação de participação realizada!</div>
        <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" (click)="close()" aria-label="Close">
          <span class="sr-only">Close</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>
      </div>
      <div *ngIf="!alertSuccess && alertSuccess != undefined" id="toast-danger" class="flex items-center w-full p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
          </svg>
          <span class="sr-only">Error icon</span>
        </div>
        <div class="ms-3 text-sm font-normal">Preencha todos os campos e aceite os termos para seguir com a confirmação!</div>
        <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-danger" (click)="close()" aria-label="Close">
          <span class="sr-only">Close</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>
      </div>
      <h2 class="text-center text-3xl md:text-5xl lg:text-7xl font-bold mb-[10%] lg:mb-[20%]">SAVE THE DATE <br> 02<span class="font-normal">JUN</span>2024</h2>
      <form class="space-y-4" [formGroup]="memberForm" (ngSubmit)="onSubmit()">
        <div>
          <label for="name" class="block text-sm font-medium">Nome completo</label>
          <input type="text" id="name" formControlName="name" name="name" class="mt-1 block w-full px-3 py-2 bg-white border border-gray-400 rounded-md text-gray-600 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="Insira o seu nome completo">
          <div *ngIf="controlForm['name'].invalid &&
        (controlForm['name'].dirty || controlForm['name'].touched)" class="text-red-600 mt-1">
            <div *ngIf="controlForm['name'].hasError('required')">Informe o seu nome.</div>
          </div>
        </div>
        <div>
          <label for="phone" class="block text-sm font-medium">Contato</label>
          <input type="text" id="phone" formControlName="phone" name="phone" class="mt-1 block w-full px-3 py-2 bg-white border border-gray-400 rounded-md text-gray-600 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="(xx)xxxxx-xxxx" mask="(00)00000-0000">
          <div *ngIf="controlForm['phone'].invalid &&
        (controlForm['phone'].dirty || controlForm['phone'].touched)" class="text-red-600 mt-1">
            <div *ngIf="controlForm['phone'].hasError('required')">Informe o seu telefone.</div>
          </div>
        </div>
        <div>
          <label for="email" class="block text-sm font-medium">E-mail</label>
          <input type="email" id="email" formControlName="email" name="email" class="mt-1 block w-full px-3 py-2 bg-white border border-gray-400 rounded-md text-gray-600 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="meuemail@exemplo.com">
          <div *ngIf="controlForm['email'].invalid &&
        (controlForm['email'].dirty || controlForm['email'].touched)" class="text-red-600 mt-1">
            <div *ngIf="controlForm['email'].hasError('required')">Informe o seu email.</div>
          </div>
        </div>
        <div>
          <label for="lgpdConsent" class="flex items-start">
            <input type="checkbox" id="lgpdConsent" formControlName="lgpdConsent" name="lgpdConsent" class="mt-1">
            <span class="ml-2 text-sm text-gray-600">Eu aceito o uso dos meus dados e receber comunicações</span>
          </label>
        </div>
        <button type="submit" class="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 rounded-md text-white font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Quero participar!</button>
      </form>
      <div class="mt-8 text-center text-sm text-gray-400">
        <p>&copy; 2024 Veredas do Reino. Todos os direitos reservados.</p>
      </div>
    </div>
  </div>
</div>
