import { Component } from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoginModel} from "../../models/login.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  login: FormGroup;
  loginDTO: LoginModel = new LoginModel();

  constructor(private auth: AuthService, private formBuilder: FormBuilder, private router: Router) {
    this.login = this.formBuilder.group({
      email: [''],
      password: ['']
    });
  }
  loginUser(){
    if (this.login.valid){
      this.loginDTO.email = this.login.value.email;
      this.loginDTO.password = this.login.value.password;
      this.auth.login(this.loginDTO).subscribe(()=>{
        console.log('Login Success!')
      }, error =>{
        console.error('Login failed', error);
      })
      console.log(document.cookie)
      this.router.navigate(['/restrito/resumo']);
    }
  }

}
