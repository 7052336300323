import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {LoginModel} from "../models/login.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = "https://landing.api.veredasdoreino.com.br";
  constructor(private http: HttpClient) { }

  login(credencials: any): Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/login`, credencials, {withCredentials: true});
  }

  isLoggedIn(){
    console.log('Cookies:', document.location.host)
    return !!document.cookie.match(/^(.*;)?\s*auth-token\s*[^;]+(.*)?$/);
  }
}
