import {Component, OnInit} from '@angular/core';
import {BackendService} from "../../service/backend.service";
import {MembersModel} from "../../models/members.model";
import {UtilsService} from "../../service/utils.service";

@Component({
  selector: 'app-dashboard',
  standalone: false,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit{

  members: MembersModel[] = [];

  constructor(private backend: BackendService, private utils: UtilsService) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData(){
    this.backend.getMembers().subscribe(res => this.members = res.users);
  }

  exportToCsv(){
    const csv = this.utils.convertToCsv(this.members);
    const bom = '\uFEFF'
    const blob = new Blob([bom+csv], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'relatorio.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}
