export class MemberModel{
  name: string;
  phone: string;
  email: string;
  agree_with_terms: boolean

  constructor() {
    this.name = '';
    this.phone = '';
    this.email = '';
    this.agree_with_terms = false;
  }
}
