import {Component, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {NgIf} from "@angular/common";
import { Location } from '@angular/common';
import {initFlowbite} from "flowbite";
import {FormsModule} from "@angular/forms";
import {BackendService} from "./service/backend.service";
import {MemberModel} from "./models/member.model";
import {HttpClient, HttpClientModule} from "@angular/common/http";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit{
  title = ''

  ngOnInit() {
    initFlowbite();
  }

}
